<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.moments.send') && companyDetailsLoaded && companyPaymentStatus === true" class="event events settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0 moment wizard">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{$t('sm.Create_a_smart_spark')}}</span>
            </CCol>         
          </CRow>
        </CCardHeader>
        <CCardBody class="event moment p-0">
          <CRow v-if="momentTemplatesLoading === true || momentTemplatesTagsLoaded === false">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="momentTemplatesLoading === false && momentTemplatesTagsLoaded === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">                       
              <div v-if="momentTemplateTags.length > 0">                      
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">                    
                    <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTemplateTags}">
                      <b-tag v-for="tag in momentTemplateTags" 
                              v-bind:key="tag.moment_tag"
                              @click.native="setSelectedMomentTemplateTags(tag.moment_tag)"
                              class="pointer"
                              v-bind:class="{'selected' : momentTemplateFilters.moment_tags.includes(tag.moment_tag)}">
                        {{tag.moment_tag}} ({{tag.moments_with_tag}})
                      </b-tag>
                    </div>
                    <div v-if="momentTemplateTags.length > 30" class="mt-2">
                      <span class="pointer" @click="toggleMomentTemplateTags()">{{!showAllMomentTemplateTags ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                    </div>
                  </CCol>
                </CRow>
              </div>
              <div v-if="momentTemplates.length > 0">
                <CRow class="w-100 m-0 moment_catalog">
                  <CCol v-for="momentTemplate in currentPageMomentTemplates[currentMomentTemplatePage - 1]" :key="momentTemplate.loyalty_moment_template_id_external" cols="3" xl="3" lg="3" md="4" class="p-0">
                    <loyaltyMomentTemplateCard :momentData="momentTemplate" 
                                               :showEditButton="false"
                                               :showMomentTag="false"
                                               parent="wizard"
                                               @click.native="getMomentTemplateDetails(momentTemplate.loyalty_moment_template_id_external);"
                                               class="pointer">
                    </loyaltyMomentTemplateCard>
                  </CCol>
                </CRow>
                <hr v-if="momentTemplates.length > momentTemplatesPerPage" class="m-0">
                <CRow v-if="momentTemplates.length > momentTemplatesPerPage" class="m-0">
                  <CCol cols="12" md="12">
                    <v-pagination class="justify-content-end" v-model="currentMomentTemplatePage" :length="momentTemplatePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('sm.No_spark_templates_available')}}</span>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded && companyDetailsLoaded" :trigger="!checkPermission('loyalty.moments.send') ? 'permission' : (companyPaymentStatus === false ? 'payment_status' : null)"/>
</template>

<script>
import axios from 'axios'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import loyaltyMomentTemplateCard from '@/components/loyalty/loyaltyMomentTemplateCard.vue';

export default {
  name: 'Moment',
  components: {
    loadingSpinner,
    noPermission,  
    loyaltyMomentTemplateCard
  }, 
  data() {
    return {
      // Common
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      companyIdExternal: null,
      modules: {},
      platformPermissions: [],
      platformPermissionsLoaded: false,
      // Company
      companyData: {},
      companyPaymentStatus: false,
      companyDetailsLoaded: false,
      // Moment Templates
      selectedMomentTemplateIdExternal: null,
      momentTemplates: [],
      momentTemplatesLoading: false,
      momentTemplatesLoaded: false,
      momentTemplateLoading: false,     
      momentTemplateFilters: {
        moment_tags: [],
      },
      momentTemplateColorOptions: [],
      momentTemplateColorMappings: {},
      currentMomentTemplatePage: 1,
      currentPageMomentTemplates: {},
      momentTemplatesPerPage: 8,
      momentTemplatePages: 0,
      // Moment Template Tags
      momentTemplateTags: [],
      momentTemplatesTagsLoaded: false,
      showAllMomentTemplateTags: false
    }
  },
  methods: { 
    // Moment Templates
    getMomentTemplates() {
      let params = {};
      params.momentFilters = this.momentTemplateFilters;
      params.include_default_templates = true;
      params.include_custom_design = true;
      params.active_only = true;
      params.randomize_templates = true;
      // Start the loader
      if(this.momentTemplatesLoaded === false) this.momentTemplatesLoading = true;
      // Get the Moment templates
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates', params)
      .then(res => {
        this.momentTemplates = res.data.data;
        // Set the pagination to the first page
        this.currentMomentTemplatePage = 1;        
        // Reset the momentTemplatePages value
        this.momentTemplatePages = 0;        
        // Loop through the Moment templates to set the pagination
        for (let i = 0; i < this.momentTemplates.length; i = i + this.momentTemplatesPerPage) {
          this.currentPageMomentTemplates[this.momentTemplatePages] = this.momentTemplates.slice(i, i + this.momentTemplatesPerPage);
          this.momentTemplatePages++;
        }
        // Set the available tags
        this.setMomentTemplateTags();
        // Stop the loader
        this.momentTemplatesLoading = false;
        // Update the momentTemplatesLoaded value
        this.momentTemplatesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getMomentTemplateDetails(templateIdExternal) {
      // Get the Moment template details      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-template/' + templateIdExternal)
      .then(res => {
        let momentTemplateData = res.data.data;
        // Open the sidebar
        this.openSidebarRight('new_loyalty_smart_moment', { 
          modules: this.modules, 
          platform_permissions: this.platformPermissions,
          moment_template_data:  momentTemplateData, 
          moment_template_color_options:  this.momentTemplateColorOptions, 
          moment_template_color_mappings:  this.momentTemplateColorMappings 
        });
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getMomentTemplateColors() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/colors')
      .then(res => {
        this.momentTemplateColorOptions = res.data.data.color_options;
        this.momentTemplateColorMappings = res.data.data.color_mappings;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    // Moment Template Tags
    setMomentTemplateTags() {
      if(this.momentTemplatesTagsLoaded === false) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/tags')
        .then(res => {
          this.momentTemplateTags = res.data.data;
          // Update the momentTemplatesTagsLoaded value
          this.momentTemplatesTagsLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for momentTemplateTags
        let momentTemplateTags = [];
        // Loop through the Moment templates
        for(var g = 0; g < this.momentTemplates.length; g++) {
          momentTemplateTags = momentTemplateTags.concat(this.momentTemplates[g].loyalty_moment_tags);
        }
        // Create momentTemplateTagsCount array with counts of remaining tags
        var momentTemplateTagsCount = momentTemplateTags.reduce((tagCount, tag) => {
          if(tagCount.hasOwnProperty(tag) === false) tagCount[tag] = 0;
          tagCount[tag]++;
          return tagCount;
        }, {});
        
        // Update the momentTemplateTags array
        this.momentTemplateTags = Object.keys(momentTemplateTagsCount).map(tag => {
          return {moment_tag: tag, moments_with_tag: momentTemplateTagsCount[tag]}; 
        });
        // Sort the momentTemplateTags
        this.momentTemplateTags.sort((a,b)=> (b.moments_with_tag - a.moments_with_tag || a.moment_tag.localeCompare(b.moment_tag)));
      }
    },
    setSelectedMomentTemplateTags(tag) {
      // Get the index of the Moment template tag in the moment_tags array of the momentTemplateFilters
      let momentTagIndex = this.momentTemplateFilters.moment_tags.findIndex(x => x === tag);
      // Remove or add the moment tag based on the index
      (momentTagIndex >= 0) ? this.momentTemplateFilters.moment_tags.splice(momentTagIndex, 1) : this.momentTemplateFilters.moment_tags.push(tag);      
      // Get the Moment templates
      this.getMomentTemplates();
    },
    toggleMomentTemplateTags() {
      (this.showAllMomentTemplateTags === false) ? this.showAllMomentTemplateTags = true : this.showAllMomentTemplateTags = false;
    },
    // Company Details
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        let companyData = res.data.data;
        // Set the companyPaymentStatus value
        this.companyPaymentStatus = companyData.payment_status;
        // Check if the company environment has been set
        if(companyData.details.environment_tag) this.companyData.environment_tag = companyData.details.environment_tag;
        // Check if the company environment has been set
        if(companyData.details.environment_name) this.companyData.environment_name = companyData.details.environment_name;               
        // Check if the company name has been set
        if(companyData.details.company_name) this.companyData.company_name = companyData.details.company_name;
        // Check if the brand name has been set
        (companyData.details.brand_name) ? this.companyData.brand_name = companyData.details.brand_name : this.companyData.brand_name = companyData.details.company_name;
        // Check if the email sender name has been set
        if(companyData.details.email_signatory) this.companyData.email_signatory = companyData.details.email_signatory;
        // Check if the company image has been set
        if(companyData.look_and_feel.company_image_id) this.companyData.company_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.company_image_id + '.png' + '/' + this.clientToken
        // Check if the company header image has been set
        if(companyData.look_and_feel.header_image_id) this.companyData.header_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.header_image_id + '.png' + '/' + this.clientToken
        // Check if the app background has been set
        if(companyData.look_and_feel.app_background_image_id) this.companyData.app_background_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.app_background_image_id + '.png' + '/' + this.clientToken;
        // Set the primary color
        (companyData.look_and_feel.primary_color) ? this.companyData.primary_color = companyData.look_and_feel.primary_color : this.companyData.primary_color = companyData.details.environment_primary_color;
        // Set the secondary color
        (companyData.look_and_feel.secondary_color) ? this.companyData.secondary_color = companyData.look_and_feel.secondary_color : this.companyData.secondary_color = companyData.details.environment_secondary_color;        
        // Set the default company image
        this.companyData.default_company_image_url = this.cdnBaseUrl + '/base/dashboard/logo/' + companyData.details.environment_tag + '_logo.png';
        // Set the default social wall image
        this.companyData.default_social_wall_image_url = this.cdnBaseUrl + '/base/dashboard/default-socialwall.jpg';
        // Update the companyDetailsLoaded value
        this.companyDetailsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    // Permissions & Modules
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');

    this.getCompanyDetails();
    this.getPlatformPermissions();
    this.checkModules();
    this.getMomentTemplates();
    this.getMomentTemplateColors();
  } 
}
</script>
